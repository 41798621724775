import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import { IMaskInput } from "react-imask"
import axios from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

import moment from "moment"
import { addDays } from "date-fns"
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ptBR from "date-fns/locale/pt-BR"

import * as S from "./styled"
import { Button } from "../../Button"
import Select from "../../Select"

moment.locale("pt-br")
registerLocale("pt-BR", ptBR)
setDefaultLocale("pt-BR")
const SweetAlert = withReactContent(Swal)

const FormContact = () => {
  const [reasonForContact, setReasonForContact] = useState(1)

  const isDaysAvailable = date => {
    const day = date.getDay()
    const dayOfMonth = date.getDate()
    const month = date.getMonth() + 1
    const recess =
      (month === 12 && dayOfMonth >= 20) || (month === 1 && dayOfMonth <= 10)
    return (
      day !== 0 && day !== 1 && day !== 3 && day !== 5 && day !== 6 && !recess
    )
  }

  const formContactInitialValues = {
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    contactReason: {
      selectedOption: {
        index: 1,
        value: "Agendar consulta",
        label: "Agendar consulta",
      },
      options: [
        {
          index: 1,
          value: "Agendar consulta",
          label: "Agendar consulta",
        },
        {
          index: 2,
          value: "Sugestões, dúvidas, reclamações ou elogios",
          label: "Sugestões, dúvidas, reclamações ou elogios",
        },
      ],
    },
    contactType: {
      selectedOption: { index: 0, value: "", label: "Selecione" },
      options: [
        {
          index: 1,
          value: "Por ligação",
          label: "Por ligação",
        },
        {
          index: 2,
          value: "Por chamada de vídeo",
          label: "Por chamada de vídeo",
        },
      ],
    },
    contactDate: "",
    contactTime: {
      selectedOption: { index: 0, value: "", label: "Selecione" },
      options: [
        {
          index: 1,
          value: "1º período - 09:00h às 09:30h",
          label: "1º período - 09:00h às 09:30h",
        },
        {
          index: 2,
          value: "2º período - 09:30h às 10:00h",
          label: "2º período - 09:30h às 10:00h",
        },
        {
          index: 3,
          value: "3º período - 10:30h às 11:00h",
          label: "3º período - 10:30h às 11:00h",
        },
        {
          index: 4,
          value: "4º período - 11:30h às 12:00h",
          label: "4º período - 11:30h às 12:00h",
        },
        {
          index: 5,
          value: "5º período - 13:30h às 14:00h",
          label: "5º período - 13:30h às 14:00h",
        },
        {
          index: 6,
          value: "6º período - 14:00h às 14:30h",
          label: "6º período - 14:00h às 14:30h",
        },
        {
          index: 7,
          value: "7º período - 15:00h às 15:30h",
          label: "7º período - 15:00h às 15:30h",
        },
        {
          index: 8,
          value: "8º período - 16:00h às 16:30h",
          label: "8º período - 16:00h às 16:30h",
        },
      ],
    },
    contactDescription: "",
  }

  const formContactValidationSchema = Yup.object().shape({
    contactName: Yup.string().required("Campo obrigatório"),
    contactEmail: Yup.string().matches(
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Digite um email válido"
    ),
    contactPhone: Yup.string()
      .min(13, "Digite um telefone válido (com DDD)")
      .max(14, "Digite um telefone válido (com DDD)")
      .required("Campo obrigatório"),
    contactReason: Yup.object().shape({
      selectedOption: Yup.object().shape({
        value: Yup.string().required("Selecione uma opção"),
      }),
    }),
    contactType: Yup.object()
      .shape({
        selectedOption: Yup.object().shape({
          value: Yup.string(),
        }),
      })
      .when("contactReason", {
        is: val => val.selectedOption.index === 1,
        then: Yup.object().shape({
          selectedOption: Yup.object().shape({
            value: Yup.string().required("Selecione uma opção"),
          }),
        }),
      }),
    contactDate: Yup.string()
      .nullable()
      .when("contactReason", {
        is: val => val.selectedOption.index === 1,
        then: Yup.string()
          .nullable()
          .required("Escolha uma data sugerida para contato"),
      }),
    contactTime: Yup.object()
      .shape({
        selectedOption: Yup.object().shape({
          value: Yup.string(),
        }),
      })
      .when("contactReason", {
        is: val => val.selectedOption.index === 1,
        then: Yup.object().shape({
          selectedOption: Yup.object().shape({
            value: Yup.string().required("Selecione uma opção"),
          }),
        }),
      }),
    contactDescription: Yup.string().required("Campo obrigatório"),
  })

  const formContactOnSubmit = async (values, { resetForm }) => {
    const {
      contactName,
      contactEmail,
      contactPhone,
      contactReason,
      contactType,
      contactDate,
      contactTime,
      contactDescription,
    } = values

    const currentUrl = typeof window !== "undefined" ? window.location.href : ""

    const contactDateFormated = moment(contactDate).format("LL")

    // Enviar para
    // const emailTo ="pontesraniery@gmail.com"
    const emailTo = "contato@ruymolina.adv.br"
    // Assunto
    const emailSubject =
      contactReason.selectedOption.index === 1
        ? `[AGENDAR CONSULTA] - Ruy Molina Advocacia`
        : `[CONTATO] - Ruy Molina Advocacia`

    const formData = new FormData()
    formData.append("to", emailTo)
    formData.append("subject", emailSubject)

    formData.append(
      "html",
      `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
      <html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
          <!--[if gte mso 9]>
            <xml>
              <o:OfficeDocumentSettings>
              <o:AllowPNG/>
              <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
          <title>${emailSubject}</title>
          <meta http–equiv="Content-Type" content="text/html; charset=utf-8">
          <meta http–equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0 ">
          <meta name="format-detection" content="telephone=no">
          <!--[if !mso]><!-->
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800" rel="stylesheet">
          <!--<![endif]-->
          <style type="text/css">
            body {
              margin: 0 !important;
              padding: 0 !important;
              -webkit-text-size-adjust: 100% !important;
              -ms-text-size-adjust: 100% !important;
              -webkit-font-smoothing: antialiased !important;
            }
            img {
              border: 0 !important;
              outline: none !important;
            }
            p {
              Margin: 0px !important;
              Padding: 0px !important;
            }
            table {
              border-collapse: collapse;
              mso-table-lspace: 0px;
              mso-table-rspace: 0px;
            }
            td, a, span {
              border-collapse: collapse;
              mso-line-height-rule: exactly;
            }
            .ExternalClass * {
              line-height: 100%;
            }
            .email_defaultlink a {
              color: inherit !important;
              text-decoration: none !important;
            }
            span.MsoHyperlink {
              mso-style-priority: 99;
              color: inherit;
            }
            span.MsoHyperlinkFollowed {
              mso-style-priority: 99;
              color: inherit;
            }
            
            @media only screen and (min-width:481px) and (max-width:699px) {
              .email_main_table {
                width: 100% !important;
              }
              .email_wrapper {
                width: 100% !important;
              }
              .email_hide {
                display: none !important;
              }
              .email_img {
                width: 100% !important;
                height: auto !important;
              }
              .email_h20 {
                height: 20px !important;
              }
              .email_padd {
                padding: 20px 10px !important;
              }
            }
            
            @media screen and (max-width: 480px) {
              .email_main_table {
                width: 100% !important;
              }
              .email_wrapper {
                width: 100% !important;
              }
              .email_hide {
                display: none !important;
              }
              .email_img {
                width: 100% !important;
                height: auto !important;
              }
              .email_h20 {
                height: 20px !important;
              }
              .email_padd {
                padding: 20px 10px !important;
              }
              .email_text1 {
                font-size: 16px !important;
                line-height: 24px !important;
              }
              u + .email_body .email_full_wrap {
                width: 100% !important;
                width: 100vw !important;
              }
            }
          </style>
        </head>
        <body class="email_body" style="margin:0px; padding:0px;" bgcolor="#efefef">
          <table class="email_full_wrap" valign="top" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#efefef" align="center">
            <tbody>
              <tr>
                <td valign="top" align="center">
                  <table class="email_main_table" style="width:700px;" width="700" cellspacing="0" cellpadding="0" border="0" align="center">
                    <tbody>
                    <!-- Header Section -->
                    <tr>
                      <td style="padding:35px 70px 30px;" class="email_padd" valign="top" bgcolor="#393E46" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:25px; font-weight:bold; line-height:22px; color:#ffffff; text-transform:normal; letter-spacing:1px; padding-bottom:0px;" valign="top" align="center">Ruy Molina Advocacia</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Header Section -->
                    
                    <!-- Content Section -->
                    <tr>
                      <td style="padding:35px 70px 30px;" class="email_padd" valign="top" bgcolor="#ffffff" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:20px; text-transform:uppercase; line-height:26px; letter-spacing:1px; color:#1B1B1B;" valign="top" align="center">${
                                contactReason.selectedOption.index === 1
                                  ? "Consulta"
                                  : "Contato"
                              }</td>
                              </tr>
                            <tr>
                              <td style="font-size:0px; line-height:0px; height:45px;" height="45">&nbsp;</td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Nome: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactName}</span></td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Email: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactEmail}</span></td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Telefone: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${
                                contactPhone || "Não informado"
                              }</span></td>
                            </tr>
                            ${
                              contactReason &&
                              contactReason.selectedOption.value
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Motivo de contato: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactReason.selectedOption.value}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            ${
                              contactType &&
                              contactReason.selectedOption.index === 1
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Tipo de contato: <span style="color:#4e4e4e; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactType.selectedOption.value}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            ${
                              contactDateFormated &&
                              contactReason.selectedOption.index === 1
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Data sugerida para contato: <span style="color:#4e4e4e; font-size:16px; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactDateFormated}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            ${
                              contactTime &&
                              contactReason.selectedOption.index === 1
                                ? `
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:15px;" valign="top" align="left">Horário sugerido para contato: <span style="color:#4e4e4e; font-size:16px; font-weight:normal; text-transform:normal; letter-spacing: 0px;">${contactTime.selectedOption.value}</span></td>
                            </tr>
                            `
                                : ""
                            }
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:16px; line-height:22px; color:#000000; text-transform:normal; letter-spacing:0px; padding-bottom:5px;" valign="top" align="left">Mensagem:</td>
                            </tr>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; line-height:25px; color:#4e4e4e; padding-bottom:15px; font-size:18px; font-weight:normal; text-transform:normal; letter-spacing: 0px;" valign="top" align="left">${contactDescription}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Content Section -->
      
                    <!-- Small Footer Section -->
                    <tr>
                      <td style="padding: 15px;" class="email_padd" valign="top" bgcolor="#393E46" align="center">
                        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
                          <tbody>
                            <tr>
                              <td style="font-family:’Open Sans’, Arial, sans-serif; font-size:12px; line-height:15px; color:#ffffff;" valign="top" align="center">Ruy Molina Web | <a href="${currentUrl}" target="_blank" style="color:#ffffff; text-decoration:underline;">Formulário de contato</a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <!-- //Small Footer Section -->
                    
                    <tr>
                      <td class="email_hide" style="line-height:1px;min-width:700px;background-color:#ffffff;"><img alt="" src="images/spacer.gif" style="max-height:1px; min-height:1px; display:block; width:700px; min-width:700px;" width="700" border="0" height="1"></td>
                    </tr>
                  </tbody>
                </table>
              </td>
              </tr>
            </tbody>
          </table>
      
          <div class="email_hide" style="white-space: nowrap; display: none; font-size:0px; line-height:0px;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        </body>
      </html>
      `
    )

    try {
      // eslint-disable-next-line no-unused-vars
      const responseData = await axios({
        method: "post",
        url: "https://ruy-molina-back.herokuapp.com/email",
        data: formData,
      })

      await SweetAlert.fire({
        title: "Sucesso!",
        text: "Sua mensagem foi enviada com sucesso!",
        icon: "success",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2500,
      })

      resetForm()
    } catch (error) {
      SweetAlert.fire({
        title: "Oops...",
        text: "Algo deu errado, tente novamente mais tarde.",
        icon: "error",
        showCloseButton: false,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
      })
    }
  }

  return (
    <Formik
      validationSchema={formContactValidationSchema}
      onSubmit={formContactOnSubmit}
      initialValues={formContactInitialValues}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <S.Form autoComplete="off" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="label" htmlFor="id_contactReason">
              Motivo do contato *
            </label>
            <Select
              inputId="id_contactReason"
              name="contactReason"
              className={`react-select-container ${
                touched.contactReason && errors.contactReason
                  ? "-is-invalid"
                  : ""
              }`}
              classNamePrefix="react-select"
              isSearchable={false}
              value={values.contactReason.selectedOption}
              onChange={e => {
                values.contactReason.selectedOption.index = e.index
                values.contactReason.selectedOption.value = e.value
                values.contactReason.selectedOption.label = e.label
                setReasonForContact(e.index)
              }}
              onBlur={handleBlur}
              options={values.contactReason.options}
            />
            {touched.contactReason && errors.contactReason && (
              <span className="error-message">
                {errors.contactReason.selectedOption.value}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_contactName">
              Nome *
            </label>
            <input
              className={`form-control ${
                touched.contactName && errors.contactName ? "-is-invalid" : ""
              }`}
              id="id_contactName"
              name="contactName"
              placeholder="Informe seu nome"
              type="text"
              value={values.contactName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {touched.contactName && errors.contactName && (
              <span className="error-message">{errors.contactName}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_contactEmail">
              Email
            </label>
            <input
              className={`form-control ${
                touched.contactEmail && errors.contactEmail ? "-is-invalid" : ""
              }`}
              id="id_contactEmail"
              name="contactEmail"
              placeholder="exemplo@email.com"
              type="email"
              value={values.contactEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.contactEmail && errors.contactEmail && (
              <span className="error-message">{errors.contactEmail}</span>
            )}
          </div>

          <div className="form-group">
            <label className="label" htmlFor="id_contactPhone">
              Telefone *
            </label>
            <IMaskInput
              className={`form-control ${
                touched.contactPhone && errors.contactPhone ? "-is-invalid" : ""
              }`}
              id="id_contactPhone"
              name="contactPhone"
              placeholder="(88) 988888888"
              type="tel"
              value={values.contactPhone}
              mask="(00) 000000000"
              unmask={false}
              onAccept={value => {
                values.contactPhone = value
              }}
              onBlur={handleBlur}
            />
            {touched.contactPhone && errors.contactPhone && (
              <span className="error-message">{errors.contactPhone}</span>
            )}
          </div>

          {/* Agendar consulta */}
          {reasonForContact === 1 ? (
            <>
              <div className="form-group">
                <label className="label" htmlFor="id_contactType">
                  Tipo de contato *
                </label>
                <Select
                  inputId="id_contactType"
                  name="contactType"
                  className={`react-select-container ${
                    touched.contactType && errors.contactType
                      ? "-is-invalid"
                      : ""
                  }`}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  value={values.contactType.selectedOption}
                  onChange={e => {
                    values.contactType.selectedOption.index = e.index
                    values.contactType.selectedOption.value = e.value
                    values.contactType.selectedOption.label = e.label
                  }}
                  onBlur={handleBlur}
                  options={values.contactType.options}
                />
                {touched.contactType && errors.contactType && (
                  <span className="error-message">
                    {errors.contactType.selectedOption.value}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label className="label" htmlFor="id_contactDate">
                  Data sugerida para contato *
                </label>

                <DatePicker
                  inputId="id_contactDate"
                  name="contactDate"
                  className={`form-control ${
                    touched.contactDate && errors.contactDate
                      ? "-is-invalid"
                      : ""
                  }`}
                  locale="pt-BR"
                  placeholderText="Escolha uma data"
                  selected={values.contactDate}
                  onChange={date => {
                    setFieldValue("contactDate", date)
                  }}
                  onBlur={handleBlur}
                  dateFormat="dd/MM/yyyy"
                  minDate={addDays(new Date(), 1)}
                  filterDate={isDaysAvailable}
                />
                {touched.contactDate && errors.contactDate && (
                  <span className="error-message">{errors.contactDate}</span>
                )}
              </div>

              <div className="form-group">
                <label className="label" htmlFor="id_contactTime">
                  Horário sugerido para contato *
                </label>
                <Select
                  inputId="id_contactTime"
                  name="contactTime"
                  className={`react-select-container ${
                    touched.contactTime && errors.contactTime
                      ? "-is-invalid"
                      : ""
                  }`}
                  classNamePrefix="react-select"
                  isSearchable={false}
                  value={values.contactTime.selectedOption}
                  onChange={e => {
                    values.contactTime.selectedOption.index = e.index
                    values.contactTime.selectedOption.value = e.value
                    values.contactTime.selectedOption.label = e.label
                  }}
                  onBlur={handleBlur}
                  options={values.contactTime.options}
                />
                {touched.contactTime && errors.contactTime && (
                  <span className="error-message">
                    {errors.contactTime.selectedOption.value}
                  </span>
                )}
              </div>
            </>
          ) : null}

          <div className="form-group">
            <label className="label" htmlFor="id_contactDescription">
              Descrição da ação *
            </label>
            <textarea
              className={`form-control -textarea ${
                touched.contactDescription && errors.contactDescription
                  ? "-is-invalid"
                  : ""
              }`}
              id="id_contactDescription"
              name="contactDescription"
              placeholder="Escreva a sua mensagem"
              rows="10"
              value={values.contactDescription}
              onChange={handleChange}
              onBlur={handleBlur}
            ></textarea>
            {touched.contactDescription && errors.contactDescription && (
              <span className="error-message">{errors.contactDescription}</span>
            )}
          </div>

          <div className="form-group -right">
            <Button
              type="submit"
              theme="primary"
              size="lg"
              full={true}
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Enviar mensagem
            </Button>
          </div>
        </S.Form>
      )}
    </Formik>
  )
}

export default FormContact
