import * as React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { PlayStore as IconPlayStore } from "@styled-icons/boxicons-logos/PlayStore"
import { AppStore as IconAppStore } from "@styled-icons/entypo-social/AppStore"
import { Phone as IconPhone } from "@styled-icons/boxicons-regular/Phone"
import { Whatsapp as IconWhatsapp } from "@styled-icons/boxicons-logos/Whatsapp"
import { WorkOutline as IconWork } from "@styled-icons/material/WorkOutline"
import { UploadFile as IconIndication } from "@styled-icons/material/UploadFile"

import { Container } from "../../../styles/container"
import { SectionHeader, SectionContent, SectionTitle } from "../"
import SectionHero from "../SectionHero"
import { Title } from "../../Text"
import FormContact from "../../Form/Contact"
import SocialLinks from "../../SocialLinks"
import { Button } from "../../Button"

import * as S from "./styled"

const SectionContactIntern = () => (
  <S.Section>
    <SectionHeader>
      <SectionHero>
        <Container>
          <SectionTitle as="h1" medium noMargin colorInverse>
            Contato
          </SectionTitle>
        </Container>
      </SectionHero>
    </SectionHeader>

    <Container>
      <SectionContent className="s-content">
        <div className="form-contact">
          <Title className="title" as="h2">
            Envie-nos uma mensagem
          </Title>
          <FormContact />
        </div>
        <div className="links-contact">
          <div className="box-contact">
            <Title className="title" as="h4">
              Redes sociais
            </Title>
            <SocialLinks contact />
          </div>

          <div className="box-contact -phone">
            <Title className="title" as="h4">
              Central Telefônica
            </Title>
            <div className="btn-wrapper">
              <Button
                as="a"
                className="btn"
                href="tel:08002521600"
                target="_blank"
                title="0800 252 1600"
                isLink={true}
                hasIcon={true}
                alignLeft={true}
              >
                <IconPhone className="icon-before" />
                0800 252 1600
              </Button>
              <Button
                as="a"
                className="btn"
                href="https://api.whatsapp.com/send?phone=5508002521600"
                target="_blank"
                title="0800 252 1600"
                isLink={true}
                hasIcon={true}
                alignLeft={true}
              >
                <IconWhatsapp className="icon-before" />
                0800 252 1600
              </Button>
            </div>
          </div>

          <div className="box-contact -phone">
            <Title className="title" as="h4">
              Outros links
            </Title>
            <div className="btn-wrapper">
              <Button
                as={AniLink}
                className="btn"
                to="/trabalhe-conosco"
                cover
                direction="bottom"
                bg="#EAEAEA"
                duration={0.8}
                title="Trabalhe conosco"
                isLink={true}
                hasIcon={true}
                alignLeft={true}
              >
                <IconWork className="icon-before" />
                Trabalhe conosco
              </Button>
              <Button
                as={AniLink}
                className="btn"
                to="/cadastre-um-processo"
                cover
                direction="bottom"
                bg="#EAEAEA"
                duration={0.8}
                title="Cadastre um processo"
                isLink={true}
                hasIcon={true}
                alignLeft={true}
              >
                <IconIndication className="icon-before" />
                Cadastre um processo
              </Button>
            </div>
          </div>
          <div className="box-contact -app">
            <Title className="title" as="h4">
              Baixe nosso APP
            </Title>
            <div className="btn-wrapper">
              <Button
                as="a"
                className="btn"
                href="https://play.google.com/store/apps/details?id=com.ruymolina1.app"
                target="_blank"
                title="Google Play"
                theme="primary"
                size="lg"
                hasIcon={true}
                alignLeft={true}
                pressonclick={true}
              >
                <IconPlayStore className="icon-before" />
                Google Play
              </Button>
              <Button
                as="a"
                className="btn"
                href="https://apps.apple.com/br/app/ruy-molina-advocacia/id1597802552"
                target="_blank"
                title="App Store"
                theme="primary"
                size="lg"
                hasIcon={true}
                alignLeft={true}
                pressonclick={true}
              >
                <IconAppStore className="icon-before" />
                App Store
              </Button>
            </div>
          </div>
        </div>
      </SectionContent>
    </Container>
  </S.Section>
)

export default SectionContactIntern
