import * as React from "react"

import Seo from "../components/seo"
import Layout from "../components/Layout"
import SectionContactIntern from "../components/Section/SectionContactIntern"
import WppButtonFloat from "../components/Button/WppButtonFloat"

const ContactPage = () => (
  <Layout>
    <Seo title="Contato" />
    <SectionContactIntern />
    <WppButtonFloat />
  </Layout>
)

export default ContactPage
